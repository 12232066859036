import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/font/font.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import * as echarts from 'echarts';
import '@vant/touch-emulator'
import { showNotify } from 'vant';
import '@/assets/font/font.css'
import VConsole from 'vconsole'
var vConsole = new VConsole();
createApp(App).use(store).use(router).use(Vant).use(vConsole).mount('#app')



// 守卫
router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if (to.meta.isloa) {

        let token = ''

        if(localStorage.getItem('user_type') == '2'){
            token = localStorage.getItem('student_token')
        }

        if(localStorage.getItem('user_type') == '1'){
            token = localStorage.getItem('admin_token')
        }

        if (!token) {
            showNotify({ type: 'danger', message: '请登录' });
            if (to.href.split('/')[1] == 'student') {
                next('/student/login')
                return;
            }else{
                next('/admin/login')
                return;
            }
        
        } else {
            next()
        }
    } else {
        next()
    }
});

export default router;